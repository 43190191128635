import React from "react";
import { AppLogo, AppText, AppTitle } from "../ui";
import styled from "styled-components";
import theme from "../../util/theme";
import { AppOfTheYearAwards } from "./index/AppOfTheYearAwards";
import { laptopSize, mobileSize } from "../../util/variables";

import DesktopImage from "../../content/img/simplified/simplified-desktop.webp";
import MobileImage from "../../content/img/simplified/simplified-mobile.webp";
import { Link } from "gatsby";
import { AppLink } from "../ui/AppLink";

const SimplifiedLandingPage = () => {
  return (
    <Main>
      <Container>
        <DesktopLogoLink to="/">
          <AppLogo size="medium" />
        </DesktopLogoLink>
        <TextWrapper>
          <MobileLogoLink to="/">
            <AppLogo size="x-small" />
          </MobileLogoLink>
          <H1>
            The world’s most important knowledge, visualized.
          </H1>
          <AppText>Join millions learning on Imprint</AppText>
          <AppLink
            to="/onboarding/questions"
            style={{
              background: "linear-gradient(to right, #5c7df6, #1E85E2)",
              marginBottom: 40,
              fontWeight: 500,
            }}
          >
            Get Started
          </AppLink>
          <Blur>
            <AppOfTheYearAwards light accolades={["AppOfTheYear", "EssentialEducationApp", "Over50kReviews"]} />
          </Blur>
        </TextWrapper>
        <picture>
          <source media={mobileSize} srcSet={MobileImage} />
          <source media={laptopSize} srcSet={DesktopImage} />
          <Img src={DesktopImage} alt="Imprint app showcase" />
        </picture>
      </Container>
    </Main>
  );
};

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  
  padding: 130px 120px;
  background-color: ${theme.LIME};
  @media ${laptopSize} {
    padding: 64px 56px;
  }
  @media ${mobileSize} {
    padding: 32px 16px;
  }
`;

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  max-height: 792px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
  background-color: ${theme.WHITE_COLOR};
  /* overflow-x: hidden; */

  @media ${mobileSize} {
    max-height: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  z-index: 2;
  padding: 108px 102px;

  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    max-width: 372px;
    padding: 24px;
    margin: 0 auto;
  }
`;

const Img = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 80%;
  max-height: 85%;
  border-bottom-right-radius: 16px;

  @media ${mobileSize} {
    position: relative;
    display: block;
    max-width: 100%;
    margin-top: auto;
    object-fit: cover;
    border-bottom-left-radius: 16px;
  }
`;

const LogoLink = styled(Link)`
  position: absolute;
  top: -64px;
  left: 0;

  @media ${laptopSize} {
    top: -52px;
  }

  @media ${mobileSize} {
    position: static;
    justify-content: center;
    margin-bottom: 32px;
  }
`;

const DesktopLogoLink = styled(LogoLink)`
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileLogoLink = styled(LogoLink)`
  display: none;
  @media ${mobileSize} {
    display: flex;
  }
`;

const H1 = styled(AppTitle)`
  max-width: 20ch;
  @media ${laptopSize} {
    font-size: 40px;
    line-height: 52px;
  }
  @media ${mobileSize} {
    font-size: 28px;
    line-height: 35px;
  }
`;

const Blur = styled.div`
  width: max-content;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  margin: -16px;
  @media ${mobileSize} {
    padding: 0;
    margin: 0 auto;
  }
`;

export default SimplifiedLandingPage;

import React, { useEffect } from "react";
import { BasicLayout } from "../layouts/BasicLayout";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import SimplifiedLandingPage from "../components/PageSegments/SimplifiedLandingPage";

export const LOCAL_STORAGE_VARIANT_KEY = "onboarding_variant";
// flow for purchasing with trial. Used for Facebook traffic
export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;

  useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_VARIANT_KEY, "simplified");
  }, []);

  return (
    <BasicLayout>
      <SimplifiedLandingPage />
    </BasicLayout >
  );
};

import React, { useEffect } from "react";
import SimplifiedLandingPage from "../components/PageSegments/SimplifiedLandingPage";
import { BasicLayout } from "../layouts/BasicLayout";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { LOCAL_STORAGE_VARIANT_KEY } from "./special-welcome";

// used for Facebook traffic
export default () => {
  const { setOnboardingLandingPage } = useOnboardingContext();

  useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_VARIANT_KEY, "simplified");
  }, []);

  return (
    <BasicLayout>
      <SimplifiedLandingPage />
    </BasicLayout>
  );
};
